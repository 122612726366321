<template>
    <div class="modal-mask">
        <div class="modal-content-container">
            <div class="new-product__header-container">
                <span class="new-product__header-title">
                    Editar arquivos anexados
                </span>
                <span @click="close()" class="material-icons new-product__closebtn-icon">
                    cancel
                </span>
            </div>
            <div class="products-page__inputs-container">
                <div class="products-page__inputs-wrapper">
                    <div class="products-page__inputs-title-wrapper">
                        <span class="products-page__inputs-title">#{{ clientInfo.name }}</span>
                    </div>
                </div>
            </div>
            <cc-loader v-if="loading" />
            <no-data-available v-if="filesList.length === 0 && !loading" :text="'Nenhum arquivo encontrado'" />
            <div v-if="filesList.length > 0 && !loading" class="modal-content-table-rows">
                <div class="modal-content-table-header">
                    <div class="modal-content-table-suplier">Nome</div>
                    <div class="modal-content-table-seller">Data de Envio</div>
                    <div class="modal-content-table-status">Tamanho (Mb)</div>
                    <div class="modal-content-table-sendby">Enviado por</div>
                    <div class="modal-content-table-actions">Ações</div>
                </div>
                <div class="modal-content-table-row" v-for="(file, idx) in filesList" :class="getSpecialBackground(idx)"
                    :key="idx">
                    <div class="modal-content-table-suplier">{{ file.arq_nome }}</div>
                    <div class="modal-content-table-seller">{{ file.created_at | formatDate }}</div>
                    <div class="modal-content-table-status">{{ formatBytes(file.arq_tamanho) }}</div>
                    <div class="modal-content-table-sendby">{{ file.usu_nome }}</div>
                    <div class="modal-content-table-actions">
                        <span @click="downloadFile(file)" class="material-icons-outlined download-icon" title="Fazer download">
                            download
                        </span>
                        <span @click="deleteFile(file)" class="material-icons-outlined remove-icon" title="Remover arquivo">
                            delete
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import * as CONSTANTS from '@/constants/constants';
import { loaderMixin } from '@/mixins/sweet-loader.mixin';

export default {
    mixins: [loaderMixin],
    props: {
        clientInfo: {
            type: Object
        }
    },
    emits: ['reload'],
    components: {
    },
    data() {
        return {
            filesList: [],
            loading: false,
        };
    },
    methods: {
        deleteFile(file) {
            this.confirm_action({
                message: `Deseja remover o arquivo '${file.arq_nome}' ?`,
                callback: () => {
                    const config = {
                        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                    }
                    axios
                        .delete(`${CONSTANTS.API_V3_URL}/admin/clients/archives/${file.arq_id}`, config)
                        .then(res => {
                            this.$swal.fire(
                                'Sucesso!',
                                'Feita remoção do arquivo.',
                                'success'
                            );
                            this.reload()
                        }).catch(err => {
                            this.$swal.fire(
                                'Erro!',
                                'Não foi possível deletar este arquivo.',
                                'error'
                            );
                            this.$swal('')
                        })
                }
            })
        },
        downloadFile(file) {
            this.$swal.fire(
                'Download iniciado!',
                'Fazendo download do arquivo.',
                'success'
            );
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                responseType: 'blob', // server returns a file
            };

            axios.get(`${CONSTANTS.API_V3_URL}/admin/clients/archives/download/${file.arq_nome}`, config)
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', file.arq_nome); // define filename to download
                    document.body.appendChild(link);
                    link.click(); // start download
                })
                .catch(error => {
                    this.$swal.fire(
                        'Erro!',
                        'Não foi possível fazer download do arquivo.',
                        'error'
                    );
                });
        },
        close() {
            this.$emit("close");
        },
        getFilesList() {
            this.loading = true;
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                params: {}
            };
            axios.get(`${CONSTANTS.API_V3_URL}/admin/clients/archives/${this.clientInfo.id}`, config)
                .then(response => {
                    this.filesList = response.data
                    this.loading = false;
                });
        },
        getSpecialBackground(idx) { if (idx % 2 != 0) return 'page-table-line-special' },
        reload() {
            this.getFilesList()
            this.$forceUpdate()
        },
        formatBytes(bytes) {
            let megabytes = (bytes / (1024 * 1024)).toFixed(2);
            return megabytes.toLocaleString() + ' MB';
        }
    },
    mounted() {
        this.getFilesList()
    },
};
</script>

<style scoped>
.modal-mask {
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
}

.modal-content-container {
    background-color: white;
    width: 80vw;
    height: 80vh;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
}

.new-product__header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 10px 10px 0px 0px;
    background-color: var(--primary-color);
}

.new-product__header-title {
    color: #ffffff;
    font-weight: 500;
    font-size: 2.6em;
}

.new-product__closebtn-icon {
    color: #ffffff;
    font-size: 30px;
    cursor: pointer;
}

.products-page__inputs-container {
    display: flex;
    margin: 1em;
}

.products-page__inputs-wrapper {
    margin: 0em 0.8em;
}

.products-page__inputs-title {
    font-size: 1.7em;
    font-weight: 400;
    letter-spacing: 0.15px;
    color: #898989;
}

.products-page__inputs {
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    padding: 0.5em;
    width: 20vw;
    height: 6vh;
    margin-top: 0.5em;
    font-size: 1.2em;
}

.products-page__inputs:focus {
    border: 1px solid #ff7110 !important;
}

.modal-content-table-rows {
    overflow-y: auto;
    height: 50vh;
    margin-top: 1vh;
    margin: 2em;
}

.modal-content-table-row {
    display: flex;
    align-items: center;
    padding: 1.2vh 1.5vw;
}

.modal-content-table-header {
    display: flex;
    background: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    padding: 1vh 1vw;
    margin-bottom: 2vh;
    font-weight: 600;
    font-size: 1.19em;
    color: #505050;
}

.modal-content-table-suplier {
    width: 25%;
}

.modal-content-table-seller {
    width: 20.5%;
}

.modal-content-table-status {
    width: 20.5%;
}
.modal-content-table-sendby {
    width: 24.5%;
}

.modal-content-table-actions {
    width: 10%;
}

.page-table-line-special {
    background: #F7F7F7;
}

.download-icon {
    color: #505050;
    cursor: pointer;
    margin-right: 15px;
}

.remove-icon {
    color: #CB4243;
    cursor: pointer;
}
</style>
